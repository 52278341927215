<template>
  <div class="page-center page-max">
    <h1>Вы успешно вошли в систему</h1>
  </div>
</template>

<script>
  export default {
    name: 'LoginDone',
    data() {
      return {}
    }
  }
</script>
